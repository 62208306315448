<template>
	<div class="article-box">
		<div class="big_box">
			<div class="box">
				<span class="spans">设备类型</span>
				<el-select style="width: 300px;" v-model="types" placeholder="请选择">
					<el-option v-for="(item,index) in machineDatas" :key="index" :label="item.machine_type_name"
						:value="item.machine_type_id">
					</el-option>

				</el-select>
				&nbsp
				<span class="spans">绑定代理</span>
				<el-select style="width: 300px;" v-model="register" placeholder="全部代理">
					<el-option v-for="(item,index) in machineDa" :key="index" :label="item.AgentName"
						:value="item.AgentName">
					</el-option>
				</el-select>
				&nbsp
				<el-input placeholder="请输入搜索关键字" v-model="keyword">
					<!-- <el-button style="height: 54px;" slot="append"  @click="machineSerchList(1)">
					</el-button> -->
				</el-input>
				<el-button style="height: 40px;" size="small" @click="reset()" round>重置</el-button>
				<el-button style="height: 40px;" size="small" type="primary" @click="machineSerchList(1)" round>搜索
				</el-button>
			</div>

			<el-col class="col" :span="8">

				<el-button class="btn" type="primary" @click="manage" round>
					<span>设备批次管理</span>
				</el-button>

				<el-button class="btn" type="primary" @click="manages" round>
					<span>设备注册申请</span>
				</el-button>

				<el-button class="btn" type="primary" @click="inputCard" round>
					<span>导入卡号</span>
				</el-button>
			</el-col>
		</div>

		<el-table :data="machineData" style="width: 100%" v-loading="loading">
			<el-table-column label="序号" type="index" align="center"></el-table-column>
			<el-table-column prop="id" label="设备ID" align="center"></el-table-column>
			<el-table-column prop="code" label="设备编码" align="center"></el-table-column>
			<el-table-column prop="cname" label="设备唯一标识" align="center"></el-table-column>
			<el-table-column prop="machine_number" label="设备编号" align="center"></el-table-column>
			<el-table-column prop="machine_type" label="设备类型" align="center" width="150">
				<template slot-scope="scope">
					<span>{{scope.row.machine_type==='1'?'永博士智能电子检测仪':scope.row.machine_type==='4'?
					'校园安防':scope.row.machine_type==='5'?'永博士智能电脑验光仪':scope.row.machine_type==='7'?'永博士睫状肌锻炼仪'
					:scope.row.machine_type==='8'?'永博士用户服务系统':''}}</span>
					<span v-if="scope.row.machine_type=='9'">校园签到</span>
					<span v-if="scope.row.machine_type=='10'">读卡器</span>
				</template>
			</el-table-column>
			<el-table-column prop="agent_name" label="绑定代理" align="center"></el-table-column>
			<el-table-column prop="organ_name" label="投放机构" align="center" width="150"></el-table-column>
			<el-table-column prop="register_info" label="设备概要" align="center" width="150">
				<!-- otype为1时是蓝牙为2是是有线 -->
				<template slot-scope="scope">
					<span v-if="scope.row.register_info">{{scope.row.register_info.osize }}{{scope.row.register_info.otype==='1'?'家庭版':'自测版'}}
						{{scope.row.register_info.is_face==='1'?'开启':'关闭'}}{{scope.row.register_info.board_type}}
						{{scope.row.register_info.remote_type}}{{scope.row.register_info.vision==='1'?
						'单视标':scope.row.register_info.vision==='2'?'多视标':''}}
						{{scope.row.register_info.link==='1'?'蓝牙':scope.row.register_info.link==='2'?'串口':''}}
						{{scope.row.register_info.is_red==='1'?'有红光':''}}
						{{scope.row.register_info.amblyopia==='1'?'有弱视':''}}
						{{scope.row.register_info.stereoscope==='1'?'有立体镜':''}}
						{{scope.row.register_info.purpose==='1'?'家庭版':scope.row.register_info.purpose==='2'?'商业版':''}}
						<strong></strong>
						</span>

				</template>
			</el-table-column>
			<el-table-column prop="register_date" label="注册时间" align="center" width="150"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button class="el_buu" type="text" size="small" @click="levelChange(scope.row)">设置设备
					</el-button>
					<el-button class="el_buu" type="text" size="small" @click="temperatures(scope.row)">查看详情</el-button>
				</template>
			</el-table-column>

		</el-table>
		<!-- 弹窗 -->

		<!-- <el-button type="text" @click="dialogFormVisible = true">打开嵌套表单的 Dialog</el-button> -->

		<el-dialog title="设备管理" :visible.sync="dialogFormVisible">
			<el-form class="el-form" :model="levelAdd">

				<el-form-item class="el-form-item" label="测温">
					<el-select v-model="levelAdd.temperature">
						<el-option v-for="(item,index) in stateOption" :key="index" :label="item.label"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el-form-item" label="游客测温">
					<el-select v-model="levelAdd.tourist">
						<el-option v-for="(item,index) in stateOptions" :key="index" :label="item.label"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el-form-item" label="读卡间隔s">
					<el-input v-model="levelAdd.space" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="levelAdds">确 定</el-button>
			</div>
		</el-dialog>


		<el-dialog title="导入卡号" :visible.sync="cardDialogFormVisible">

				<el-upload
				  class="upload-demo"
				  :action="$url+'/erature/kidImp'"
				  :on-remove="handleRemoveFile"
				  :on-success="handleUploadFile"
				  :limit="1" :headers="{token:token}"
				  :file-list="fileList">
				  <el-button size="small" type="primary">点击上传</el-button>
				  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
				
			<div slot="footer" class="dialog-footer">
				
				<el-button @click="cardDialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleInputCard">确 定</el-button>
			</div>
		</el-dialog>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		agentList,
		machineType,
		machineList,
		cameraSet, //设置设备属性
	} from '@/api/adminApi.js'

	export default {
		data() {
			return {
				fileList: [],
				cardDialogFormVisible: false,
				stateOptions: [{
						label: '支持',
						id: 1
					},
					{
						label: '不支持',
						id: 0
					},

				],
				stateOption: [{
						label: '支持',
						id: 1
					},
					{
						label: '不支持',
						id: 0
					},

				],
				dialogFormVisible: false,
				keyword: '', // 搜索关键字
				machineData: [], // 设备列表
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				infoList: [],
				register: '',
				agentName:'',
				levelAdd: {
					temperature: null,
					tourist: null,
					space: null
				},
				machineDatas: [],
				types: '', //类型
				machineDa: [],
				token:localStorage.getItem('Token')
			}
		},
		mounted() {
			this.machineSerchList(1) // 获取第一页设备列表
			this.machine() //获取设备类型信息
			this.userList()
		},
		methods: {
			handleInputCard() {
				this.cardDialogFormVisible = false
			},
					
				handleRemoveFile() {
				},
			handleUploadFile(res) {
				console.log(res)
				if (res.status.code!=1) {
					this.$message.error(res.status.msg)
				} else {
					this.$message.success('导入成功')
					this.cardDialogFormVisible=false
				}
				this.fileList = []
			},
			inputCard() {
				this.cardDialogFormVisible = true
			},
			reset() { // 重置按钮
				this.register = ''
				this.types = ''
				this.currpage = 1
				this.machineSerchList(this.currpage)
			},
			//设备类型
			machine(currpage) {
				this.loading = true
				const data = {
					keyword: this.keyword,
					page: this.currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				machineType(data).then(res => { // 这时候想要获取this.machineDatas用debugger
					this.machineDatas = res.data
				}).catch(err => {
					console.log(err)
				})
			},
			userList(currpage) {
				this.loading = true
				const data = {
					keyword: this.keyword,
					page: this.currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				agentList(data).then(res => {
					this.machineDa = res.data.List
				}).catch(err => {
					console.log(err)
				})
			},
			levelChange(obj) { // 点击按钮


				this.levelAdd = obj
				console.log(obj)

				this.dialogFormVisible = true;
			},
			levelAdds() { // 点击按钮	
				const data = {
					machine_id: this.levelAdd.id,
					temperature: this.levelAdd.temperature,
					tourist: this.levelAdd.tourist,
					space: this.levelAdd.space
					// show_val: this.levelAdd.show_val,s
					// ask: JSON.stringify(this.levelAdd.ask)

				}
				cameraSet(data).then(res => {
					if (res.status.code == 1) {
						this.$message.success('操作成功');
						this.machineSerchList(this.currpage) // 获取列表
						this.dialogFormVisible = false;
					} else {
						this.$message.error(res.status.msg);
					}
				}).catch(err => {
					// console.log(err)
				})

			},

			machineSerchList(currpage) { // 设备列表
				this.loading = true
				const data = {
					machine_type: this.types,
					agent_name: this.register,
					machine_number: this.keyword,
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				machineList(data).then(res => {
					this.machineData = res.data.list // 设备列表		
					this.machineData.forEach(i => {
				
					// 	/*****************支持不支持******************/
						if(i.temperature === '1'){
							this.$set(i,'temperature','支持')
						}else{
							this.$set(i,'temperature','不支持')
						}
						if(i.tourist === '1'){
							this.$set(i,'tourist','支持')
						}else{
							this.$set(i,'tourist','不支持')
						}
					})
				
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					// console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.machineSerchList(val)
				this.currpage = val
			},
			manage() {
				this.$router.push('/machines')
			},
			manages() {
				this.$router.push('/registrationDevice')
			},
			temperatures(item) {
				this.$router.push({
					name: "deviceDetails",
					params: {
						id: item.id,
						machine_type: item.machine_type,
						agent_name: item.agent_name,
						organ_name: item.organ_name,
						register_user: item.register_user,
						city: item.city,
						register_date: item.register_date,
						code: item.code,
						machine_number: item.machine_number,
						osize: item.register_info.osize,
						otype: item.register_info.otype,
						register_info: item.register_info,
						launch_time: item.launch_time,
						insert_time: item.insert_time,
						batch: item.batch,
						remote_type: item.register_info.remote_type,
						vision: item.register_info.vision,
						board_type: item.board_type,
						is_face: item.register_info.is_face,
						link: item.register_info.link,
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.big_box {
		width: 100%;
		display: flex;

		.box {
			display: flex;

			el-button {
				height: 20px;
			}

		}

		.col {

			width: 100%;
			display: flex;
			justify-content: flex-end;

			.btn {
				width: 10.5625rem;
				height: 3.125rem;
				background-color: #003882;
				font-size: 1.25rem;



			}
		}
	}

	.el_buu {
		font-size: 16px;
		color: #00BFBF;
	}

	.el-form {
		display: flex;
		flex-direction: column-reverse;

	}

	.el-form-item {
		display: flex;
	}

	.el-dialog {
		width: 254px;
	}

	.spans {
		display: inline-block;
		width: 150px;
		height: 42px;
		line-height: 42px;
	}
</style>
